import numeral from "numeral";
import { makeStyles } from "tss-react/mui";
import { useResponsive } from "../../hooks/useResponsive";
import { Utility } from "../../Utility";

const useStyles = makeStyles<{ isDesktop: boolean; donePercent: number }>()(
  (theme, { isDesktop, donePercent }) => ({
    root: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(2),
      paddingRight: isDesktop ? theme.spacing(2) : theme.spacing(1),
      paddingLeft: isDesktop ? theme.spacing(2) : theme.spacing(1),
    },
    labelRow: {
      display: "flex",
      paddingLeft: theme.spacing(0.5),
      paddingRight: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5),
    },
    progressRow: {
      display: "flex",
      height: 20,
    },
    progressDone: {
      backgroundColor: "#86e127",
      borderTopLeftRadius: 8,
      borderBottomLeftRadius: 8,
      borderTopRightRadius: donePercent === 100 ? 8 : 0,
      borderBottomRightRadius: donePercent === 100 ? 8 : 0,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontSize: "0.9rem",
    },
    progressNotDone: {
      backgroundColor: "#eee",
      borderTopRightRadius: 8,
      borderBottomRightRadius: 8,
    },
  })
);

type PageProps = {
  subtotal: number;
};
export const Progress = ({ subtotal }: PageProps) => {
  const { isDesktop } = useResponsive();
  let donePercent = Math.round((subtotal / 6.99) * 100);
  let notDonePercent = 100 - donePercent;
  if (donePercent > 100) {
    donePercent = 100;
    notDonePercent = 0;
  }
  const { classes } = useStyles({ isDesktop, donePercent });
  return (
    <div className={classes.root}>
      <div className={classes.labelRow}>
        <div style={{ width: `50%` }}>
          Subtotal {numeral(subtotal).format("$0,0.00")}
        </div>
        <div style={{ width: `50%`, textAlign: "right" }}>
          {donePercent === 100
            ? "You're ready to checkout!"
            : `You're almost there!`}
        </div>
      </div>
      {donePercent < 100 && (
        <div className={classes.progressRow}>
          <div
            className={classes.progressDone}
            style={{ width: `${donePercent}%` }}
          >
            {donePercent}%
          </div>
          <div
            className={classes.progressNotDone}
            style={{ width: `${notDonePercent}%` }}
          />
        </div>
      )}
    </div>
  );
};

export default Progress;
