import { Badge, Menu, MenuItem } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import NotificationsIcon from "@mui/icons-material/Notifications";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AssignmentIcon from "@mui/icons-material/Assignment";
import MenuIcon from "@mui/icons-material/Menu";
import MessageIcon from "@mui/icons-material/Message";
import StoreIcon from "@mui/icons-material/Store";
import { useResponsive } from "../../hooks/useResponsive";
import Cart from "../Cart/Cart";
import { useEffect, useState } from "react";
import { useAuth } from "../../hooks/useAuth";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ObjectType } from "../../Types";

const useStyles = makeStyles<{ isDesktop: boolean; headerShadow?: boolean }>()(
  (theme, { isDesktop, headerShadow }) => ({
    root: {
      position: "sticky",
      top: 0,
      height: isDesktop ? 80 : 40,
      display: "flex",
      alignItems: "center",
      width: "100%",
      marginLeft: "auto",
      marginRight: "auto",
      marginTop: !isDesktop ? theme.spacing(1) : undefined,
      backgroundColor: "#fff",
      zIndex: 100,
      boxShadow:
        headerShadow || headerShadow === undefined
          ? "0 12px 12px -5px rgba(100,100,100,0.1)"
          : undefined,
      marginBottom: isDesktop ? 0 : theme.spacing(0.8),
    },
    logo: {
      fontSize: isDesktop ? 32 : 32,
      fontFamily: "Pacifico",
      fontWeight: "bold",
      color: "#334",
      marginLeft: isDesktop ? theme.spacing(2) : theme.spacing(1),
      cursor: "pointer",
    },
    menuContainer: {
      marginTop: theme.spacing(1.38),
      marginLeft: theme.spacing(5),
      display: "flex",
      flex: 1,
    },
    menuItem: {
      marginLeft: theme.spacing(5),
      fontSize: 21,
      textTransform: "uppercase",
      cursor: "pointer",
      paddingBottom: theme.spacing(0.75),
      borderBottom: "1px solid transparent",
    },
    menuIcon: {
      marginRight: theme.spacing(2),
      lineHeight: "3rem",
    },
    notificationIcon: {
      padding: theme.spacing(3),
      paddingLeft: theme.spacing(2),
      // paddingBottom: theme.spacing(2),
      cursor: "pointer",
    },
    icon: {
      padding: isDesktop ? theme.spacing(3) : theme.spacing(1),
      paddingLeft: theme.spacing(2),
      cursor: "pointer",
    },
    selected: {
      borderBottomColor: "#333",
    },
  })
);

type PageProps = {
  publicMenu?: ObjectType[];
  headerShadow?: boolean;
};
export const PublicHeader = ({ publicMenu, headerShadow }: PageProps) => {
  const { isDesktop, isTablet, isMobile } = useResponsive();
  const { isSuperAdmin, isAuthenticated, logout } = useAuth();
  const { classes } = useStyles({ isDesktop, headerShadow });
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [mobileMenuEl, setMobileMenuEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const mobileMenuOpen = Boolean(mobileMenuEl);
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (isAuthenticated) {
      setAnchorEl(event.currentTarget);
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLDivElement>) => {
    setMobileMenuEl(event.currentTarget);
  };
  const handleMobileMenuClose = () => {
    setMobileMenuEl(null);
  };
  const handleLogout = () => {
    setAnchorEl(null);
    logout();
  };
  // useEffect(() => {
  //   const observer = new IntersectionObserver(
  //     ([e]) => {
  //       console.log("e: ", e);
  //       e.target.setAttribute("stuck", "test");
  //     },
  //     { threshold: [1] }
  //   );

  //   if (el) {
  //     console.log("el: ", el);
  //     observer.observe(el);
  //   }

  //   return () => {
  //     // observer.unobserve();
  //   };
  // }, [window.scrollY, el]);
  return (
    <div
      className={classes.root}
      id="nav"
      // onScroll={() => {
      //   const observer = new IntersectionObserver(
      //     ([e]) => {
      //       console.log("e: ", e);
      //       e.target.setAttribute("stuck", "test");
      //     },
      //     { threshold: [1] }
      //   );

      //   const el = document.querySelector("#nav");

      //   if (el) {
      //     console.log("el: ", el);
      //     observer.observe(el);
      //   }
      // }}
    >
      <div className={classes.logo} onClick={() => navigate("/")}>
        Sticker
      </div>
      {isDesktop && (
        <div className={classes.menuContainer}>
          {publicMenu?.map((menuItem) => (
            <div
              className={`${classes.menuItem} ${
                menuItem.path === pathname && classes.selected
              }`}
              onClick={() => navigate(menuItem.path)}
            >
              {menuItem.name}
            </div>
          ))}
        </div>
      )}
      {!isDesktop && <div style={{ flex: 1 }}></div>}
      {/* {isAuthenticated && (
        <div className={classes.notificationIcon}>
          <Badge badgeContent={null} color="primary">
            <NotificationsIcon fontSize="medium" />
          </Badge>
        </div>
      )} */}
      <div className={classes.icon} onClick={handleClick}>
        {!isAuthenticated && (
          <>
            <Link
              to="/login"
              style={{ display: "inline-block", marginRight: 16 }}
            >
              Sign In
            </Link>
            <Link
              to="/signup"
              style={{
                display: "inline-block",
              }}
            >
              Sign Up
            </Link>
          </>
        )}
        {isAuthenticated && (
          <>
            <Badge badgeContent={null} color="primary">
              <AccountCircleIcon
                fontSize="medium"
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
              />
            </Badge>
          </>
        )}
      </div>
      {isAuthenticated && (
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem
            onClick={() => {
              handleClose();
              navigate("/creator-dashboard/profile");
            }}
          >
            <AccountCircleIcon fontSize="small" className={classes.menuIcon} />
            Profile
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleClose();
              navigate("/creator-dashboard/referral-rewards");
            }}
          >
            <AttachMoneyIcon fontSize="small" className={classes.menuIcon} />
            Referral Rewards
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleClose();
              navigate("/creator-dashboard/customer-orders");
            }}
          >
            <AssignmentIcon fontSize="small" className={classes.menuIcon} />
            Purchase History
          </MenuItem>
          {/* <MenuItem
            onClick={() => {
              handleClose();
              navigate("/creator-dashboard/messages");
            }}
          >
            <Badge
              badgeContent={3}
              color="primary"
              className={classes.menuIcon}
            >
              <MessageIcon fontSize="small" />
            </Badge>
            Messages
          </MenuItem> */}
          <MenuItem
            onClick={() => {
              handleClose();
              navigate("/creator-dashboard/dashboard");
            }}
          >
            <StoreIcon fontSize="small" className={classes.menuIcon} />
            Creator Dashboard
          </MenuItem>

          <MenuItem onClick={handleLogout}>
            <ArrowBackIcon fontSize="small" className={classes.menuIcon} />
            Sign Out
          </MenuItem>
        </Menu>
      )}
      <Cart />
      {!isDesktop && (
        <div className={classes.icon} onClick={handleMobileMenuOpen}>
          <MenuIcon fontSize="medium" />
        </div>
      )}
      <Menu
        id="basic-menu"
        anchorEl={mobileMenuEl}
        open={mobileMenuOpen}
        onClose={handleMobileMenuClose}
        // onClick={handleMobileMenuClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {publicMenu?.map((menuItem) => (
          // <Link to={menuItem.path}>
          <MenuItem onClick={() => navigate(menuItem.path)}>
            {menuItem.name}
          </MenuItem>
          // </Link>
        ))}

        {/* <Link to="/privacy-policy"> */}
        <MenuItem onClick={() => navigate("/privacy-policy")}>
          Privacy Policy{" "}
        </MenuItem>
        {/* </Link> */}
        <Link to="/shipping-policy">
          <MenuItem>Shipping & Delivery</MenuItem>
        </Link>
        <Link to="/return-policy">
          <MenuItem>Return & Refund Policy</MenuItem>
        </Link>
        <Link to="/terms-of-service">
          <MenuItem>Terms Of Service</MenuItem>
        </Link>
        {/* <MenuItem
          onClick={() => {
            handleMobileMenuClose();
            // navigate("/creator-dashboard/profile");
          }}
        >
          <AccountCircleIcon fontSize="small" className={classes.menuIcon} />
          Profile
        </MenuItem> */}
      </Menu>
    </div>
  );
};

export default PublicHeader;
