import { useSelector } from "react-redux";
import { Formik } from "formik";
import { makeStyles } from "tss-react/mui";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { Badge, Drawer, Box, Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import VerifiedIcon from "@mui/icons-material/Verified";
import CircularProgress from "@mui/material/CircularProgress";
import numeral from "numeral";
import ReactPixel from "react-facebook-pixel";
import { getItems } from "../../store/cartSlice";
import { useState } from "react";
import { DrawerHeader } from "../UI/DrawerHeader";
import colors from "../../colors.json";
import lengths from "../../lengths.json";
import { ObjectType } from "../../Types";
import { Dropdown } from "../FormFields/Dropdown";
import { Image } from "../UI/Image";
import { useQuery, useQueryClient } from "react-query";
import { getFiltered, post, update } from "../../axios/api";
import { DocumentType } from "../../Enums";
import { getCartId, getUser } from "../../store/authSlice";
import { useNavigate } from "react-router-dom";
import { useResponsive } from "../../hooks/useResponsive";
import { Utility } from "../../Utility";
import { Input } from "../FormFields";
import { Loading } from "../UI/Loading";
import PromoCode from "../UI/PromoCode";
import Progress from "./Progress";

const useStyles = makeStyles<{ isDesktop: boolean }>()(
  (theme, { isDesktop }) => ({
    root: {
      marginRight: isDesktop ? theme.spacing(6) : 0,
    },
    accountIcon: {},
    cartIcon: {
      padding: isDesktop ? theme.spacing(3) : theme.spacing(1),
      paddingLeft: theme.spacing(2),
      // paddingBottom: theme.spacing(2),
      "&:hover": {
        cursor: "pointer",
      },
    },
    drawer: {
      width: isDesktop ? 500 : "100%",
    },
    drawerTitle: {
      marginLeft: theme.spacing(3),
    },
    cartItem: {
      display: "flex",
      // width: "100%",

      paddingTop: theme.spacing(2),
      paddingRight: isDesktop ? theme.spacing(2) : theme.spacing(1),
      paddingBottom: theme.spacing(2),
      paddingLeft: isDesktop ? theme.spacing(2) : theme.spacing(1),
      minHeight: 76,
    },
    cartItemInfo: {
      flex: 1,
      paddingLeft: theme.spacing(2),
      // maxWidth: 170,
      width: "100%",
    },
    cartItemTitle: {
      // textOverflow: "ellipsis",
      // overflow: "hidden",
      // whiteSpace: "nowrap",
      marginTop: theme.spacing(1.25),
      paddingRight: theme.spacing(2),
    },
    cartItemOptions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      paddingLeft: theme.spacing(0),
      listStyleType: "none",
    },
    cartItemOption: {
      marginTop: theme.spacing(0.5),
      marginBottom: theme.spacing(0.5),
      color: "#444",
      fontSize: 14,
    },
    cartItemQuantity: {
      display: "flex",
      alignItems: "center",
      flexDirection: isDesktop ? "row" : "column",
      // paddingRight: isDesktop ? theme.spacing(2) : theme.spacing(2),
      // marginRight: isDesktop ? undefined : theme.spacing(1),
    },
    cartItemPrice: {
      marginLeft: isDesktop ? undefined : "auto",
      marginRight: isDesktop ? theme.spacing(2) : "auto",
      marginBottom: isDesktop ? undefined : theme.spacing(2),
      whiteSpace: "nowrap",
    },
    subtotal: {
      textAlign: "right",
      marginRight: isDesktop ? theme.spacing(2) : theme.spacing(1),
    },
    note: {
      fontSize: "0.9rem",
      textAlign: "center",
      // marginTop: theme.spacing(0.25),
    },
  })
);

type PageProps = {};
export const Cart = ({}: PageProps) => {
  const { isDesktop } = useResponsive();
  const { classes } = useStyles({ isDesktop });
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const cartId = useSelector(getCartId);
  const user = useSelector(getUser);
  const userId = user?.id;
  const [isOpen, setIsOpen] = useState(false);
  const [isCheckingOut, setIsCheckingOut] = useState(false);
  const [quantityUpdating, setQuantityUpdating] = useState(false);
  const [promoCodeError, setPromoCodeError] = useState("");
  const toggleDrawer = () => setIsOpen(!isOpen);
  const quantities = Utility.getQuantities();
  const quantitiesList = Object.values(quantities).filter(
    (length: ObjectType) => !length.disabled
  );

  const { data, isLoading } = useQuery(
    [DocumentType.CART_ITEM, cartId],
    () => getFiltered({ documentType: DocumentType.CART_ITEM, cartId }),
    { enabled: !!cartId }
  );
  if (isLoading) {
    return (
      <div className={classes.root}>
        <div onClick={toggleDrawer} className={classes.cartIcon}>
          <Badge badgeContent={"loading"} color="primary">
            <ShoppingCartIcon fontSize="medium" />
          </Badge>
        </div>
      </div>
    );
  }
  if (!isLoading && !data) {
    return (
      <div className={classes.root}>
        <div onClick={toggleDrawer} className={classes.cartIcon}>
          <ShoppingCartIcon fontSize="medium" />
        </div>
      </div>
    );
  }
  const cartItems: ObjectType[] = data.data;
  const subtotal = cartItems.reduce(
    (acc, item) => acc + item.quantity * item.price,
    0
  );
  return (
    <div className={classes.root}>
      <div onClick={toggleDrawer} className={classes.cartIcon}>
        <Badge badgeContent={cartItems?.length || null} color="primary">
          <ShoppingCartIcon fontSize="medium" />
        </Badge>
      </div>
      <Drawer
        anchor="right"
        open={isOpen}
        onClose={toggleDrawer}
        className={classes.drawer}
        PaperProps={{ className: classes.drawer }}
      >
        <DrawerHeader
          title="Cart"
          direction="right"
          onClose={() => setIsOpen(false)}
        />
        {cartItems.length === 0 && (
          <div
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ShoppingCartIcon />
            <p style={{ marginLeft: 8 }}>Your cart is empty!</p>
          </div>
        )}
        {cartItems.map((item: ObjectType) => {
          return (
            <div className={classes.cartItem}>
              <Image
                size={100}
                filename={item.primaryImageId}
                imageBuffer={item.imageBuffer}
                dropShadow
              />
              <div className={classes.cartItemInfo}>
                <div className={classes.cartItemTitle}>{item.title}</div>
                <div className={classes.cartItemOption}>
                  {(lengths as ObjectType)[item.lengthId]?.name}
                </div>
              </div>
              <div>
                <div className={classes.cartItemQuantity}>
                  <div className={classes.cartItemPrice}>
                    {numeral(item.price).format("$0,0.00")} ea
                  </div>
                  <Formik
                    initialValues={{
                      id: item.id,
                      quantity: item.quantity,
                    }}
                    enableReinitialize={true}
                    onSubmit={async (values) => {
                      setQuantityUpdating(true);
                      await update({
                        documentType: DocumentType.CART_ITEM,
                        ...values,
                      });
                      await queryClient.refetchQueries([
                        DocumentType.CART_ITEM,
                      ]);
                      await queryClient.refetchQueries(
                        `${DocumentType.FRONT_PAGE}Filtered`
                      );
                      setQuantityUpdating(false);
                    }}
                  >
                    {({
                      values,
                      setFieldValue,
                      handleSubmit,
                      isSubmitting,
                      errors,
                    }) => {
                      return (
                        <form onSubmit={handleSubmit}>
                          <Dropdown
                            label="Quantity"
                            name="quantity"
                            value={values.quantity}
                            options={quantitiesList}
                            onUpdate={(name, value) => {
                              setFieldValue(name, value);
                              handleSubmit();
                            }}
                            style={{ minWidth: 90 }}
                            size={isDesktop ? "small" : "small"}
                          />
                        </form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          );
        })}
        {cartItems.length > 0 && (
          <>
            <Progress subtotal={subtotal} />
            <LoadingButton
              variant="contained"
              style={{ margin: 12 }}
              loading={isCheckingOut}
              disabled={quantityUpdating || subtotal < 6.99}
              onClick={async () => {
                setIsCheckingOut(true);
                ReactPixel.track("InitiateCheckout");
                const url = await post({
                  cartId,
                  userId,
                  documentType: DocumentType.STRIPE,
                  method: "createCheckoutSession",
                });

                document.location.href = url;
              }}
            >
              Checkout
            </LoadingButton>
            {subtotal < 6.99 && (
              <div className={classes.note}>$6.99 minimum to place order</div>
            )}
          </>
        )}
      </Drawer>
    </div>
  );
};

export default Cart;
