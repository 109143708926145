import { Modal } from "@mui/material";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { makeStyles } from "tss-react/mui";
import { getImage } from "../../axios/image";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const PROCESSED_IMAGE_BUCKET = process.env.REACT_APP_PROCESSED_IMAGE_BUCKET;
const IMAGE_BUFFER_BUCKET = process.env.REACT_APP_IMAGE_BUFFER_BUCKET;

type StyleType = {
  size: number;
  isSquare?: boolean;
  width?: string | number;
  height?: string | number;
  codeBuffer?: string;
  dropShadow?: boolean;
};
const useStyles = makeStyles<StyleType>()(
  (theme, { size, isSquare, width, height, codeBuffer, dropShadow }) => ({
    root: {
      // backgroundPosition: "center center",
      // backgroundSize: "contain",
      // backgroundRepeat: "no-repeat",
      // backgroundClip: "content-box",
      width: width || size,
      minWidth: width || size,
      height: height || (isSquare ? size : size / (4 / 3)),
      borderRadius: size <= 48 ? 2 : 4,
      marginTop: codeBuffer ? "auto" : undefined,
      marginBottom: codeBuffer ? "auto" : undefined,
      filter: dropShadow
        ? `drop-shadow(2px 2px 2px rgba(50,50,50,0.1))
      drop-shadow(-2px 2px 2px rgba(50,50,50,0.1))
      drop-shadow(2px -2px 2px rgba(50,50,50,0.1))
      drop-shadow(-2px -2px 2px rgba(50,50,50,0.1))`
        : undefined,
    },
    modal: {
      backgroundColor: "#fff",
      width: "calc(100% - 16px)",
      height: "calc(100% - 16px)",
      padding: theme.spacing(1),
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      overflow: "scroll",
    },
    modalImage: {
      width: "100%",
      objectFit: "contain",
      filter: dropShadow
        ? `drop-shadow(2px 2px 2px rgba(50,50,50,0.1))
      drop-shadow(-2px 2px 2px rgba(50,50,50,0.1))
      drop-shadow(2px -2px 2px rgba(50,50,50,0.1))
      drop-shadow(-2px -2px 2px rgba(50,50,50,0.1))`
        : undefined,
    },
    modalCloseButton: {
      position: "absolute",
      top: theme.spacing(2),
      right: theme.spacing(2),
      zIndex: 1000,
    },
  })
);

type ImageType = {
  filename?: string;
  size: 100 | 192 | 300 | 768;
  height?: string | number;
  width?: string | number;
  className?: string;
  blobUrl?: string;
  codeBuffer?: string;
  imageBuffer?: string;
  file?: any;
  onClick?: () => void;
  isCalculating?: boolean;
  isSquare?: boolean;
  alt?: string;
  dropShadow?: boolean;
  zoom?: boolean;
};
export const Image = ({
  filename,
  size,
  className,
  blobUrl,
  codeBuffer = "",
  imageBuffer = "",
  onClick,
  isSquare = false,
  height,
  width,
  alt,
  dropShadow,
  zoom,
}: ImageType) => {
  if (codeBuffer || imageBuffer || blobUrl) {
    height = "fit-content";
  }
  const { classes } = useStyles({
    size,
    isSquare,
    width,
    height,
    codeBuffer,
    dropShadow,
  });
  let filePath;
  let rawFilePath;
  const [bufferFilePath, setBufferFilePath] = useState("");
  const [modalOpen, setModalOpen] = useState(false);

  // const { data, isLoading: isPreviewLoading } = useQuery(
  //   ["image", codeBuffer],
  //   ({ signal }) => ,
  //   {
  //     enabled: !!codeBuffer,
  //     staleTime: Infinity,
  //   }
  // );
  // const imageBuffer = data?.data;
  // const imageSrc = `data:image/png;base64,${imageBuffer}`;

  useEffect(() => {
    const image = async () => {
      const { data } = await getImage(codeBuffer, {});
      const imageSrc = `data:image/png;base64,${data}`;
      setBufferFilePath(imageSrc);
    };

    if (codeBuffer) {
      image();
    }
  }, [codeBuffer]);

  if (imageBuffer) {
    filePath = `data:image/png;base64,${imageBuffer}`;
    rawFilePath = `data:image/png;base64,${imageBuffer}`;
  } else if (blobUrl) {
    filePath = blobUrl;
  } else if (filename) {
    filePath = `${PROCESSED_IMAGE_BUCKET}/${filename}/1000.webp`;
    rawFilePath = `${PROCESSED_IMAGE_BUCKET}/${filename}/raw.webp`;
  }

  // else if (codeBuffer) {
  //   filePath = imageSrc;
  // }
  if (!filePath && !bufferFilePath) {
    return <></>;
  }
  return (
    // <div
    //   className={`${className} ${classes.root} }`}
    //   style={{ backgroundImage: `url(${filePath})` }}
    //   onClick={onClick}
    // />
    <>
      <img
        src={filePath || bufferFilePath}
        alt={alt}
        className={`${className} ${classes.root}`}
        onTouchStart={(e) => {
          if (zoom) {
            setModalOpen(true);
          }
        }}
      />
      <Modal open={modalOpen}>
        <div className={classes.modal}>
          <IconButton
            className={classes.modalCloseButton}
            onClick={() => setModalOpen(false)}
          >
            <CloseIcon />
          </IconButton>
          <img src={rawFilePath} alt={alt} className={classes.modalImage} />
        </div>
      </Modal>
    </>
  );
};

export default Image;
