import {
  Badge,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { useResponsive } from "../../hooks/useResponsive";
import { useSearch } from "../../hooks/useSearch";
import { ObjectType } from "../../Types";
import { Dropdown } from "../FormFields/Dropdown";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(5),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(5),
    paddingLeft: theme.spacing(0),
    "& .MuiBadge-badge": {
      right: -17,
      top: 15,
    },
  },
  mobileRoot: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  header: {},
  item: {
    fontSize: 18,
    lineHeight: 1.8,
    textTransform: "uppercase",
    marginBottom: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    marginLeft: theme.spacing(1),
    paddingRight: theme.spacing(2),
    whiteSpace: "nowrap",
  },
  subCategory: {
    fontSize: 16,
    paddingLeft: theme.spacing(1),
    marginLeft: theme.spacing(1),
    cursor: "pointer",
  },
  selected: {
    backgroundColor: "#334",
    color: "#fff",
    borderRadius: 4,
  },
}));

type PageProps = {
  navigation: ObjectType;
  path?: string;
};
export const Navigation = ({ navigation, path }: PageProps) => {
  const { isDesktop } = useResponsive();
  const classes = useStyles();
  const navigate = useNavigate();
  const { isSuperAdmin, user } = useAuth();
  // console.log("isSuperAdmin: ", isSuperAdmin);
  // console.log("path: ", path);

  const mobileCategories = navigation.categories.filter(
    (category: ObjectType) =>
      !category.private ||
      (category.private && isSuperAdmin) ||
      (category.stripe && user?.stripeActive)
  );

  const handleOptionChanged = (e: SelectChangeEvent<string>) => {
    // console.log("e: ", e);
    if (e?.target?.value) {
      // setValue(e.target.value as "");
      navigate(e.target.value);
    }
  };

  if (!isDesktop) {
    return (
      <div className={classes.mobileRoot}>
        <FormControl fullWidth>
          <InputLabel id="select-label">Category</InputLabel>
          <Select
            labelId="select-label"
            value={path}
            label="Category"
            onChange={handleOptionChanged}
          >
            {mobileCategories.map((category: ObjectType) => {
              // console.log("category.path: ", category.path);
              return (
                <MenuItem value={category.path} key={category.path}>
                  {category.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      {navigation.categories.map((category: ObjectType) => {
        // console.log("category;", isSuperAdmin, category);
        if (
          (category.private && !isSuperAdmin) ||
          (category.stripe && !user?.stripeActive)
        ) {
          return <></>;
        }
        return (
          <div
            className={`${classes.item} ${
              path && category.path === path ? classes.selected : undefined
            }`}
            onClick={() => category.path && navigate(category.path)}
            style={{ cursor: category.path ? "pointer" : undefined }}
          >
            <div>
              <Badge badgeContent={category.badge} color="primary">
                {category.name}
              </Badge>
            </div>
            {category.children?.map((subCategory: ObjectType) => (
              <div
                className={`${classes.subCategory} ${
                  path && subCategory.path === path
                    ? classes.selected
                    : undefined
                }`}
                onClick={() => subCategory.path && navigate(subCategory.path)}
              >
                <Badge badgeContent={subCategory.badge} color="primary">
                  {subCategory.name}
                </Badge>
              </div>
            ))}
          </div>
        );
      })}
    </div>
  );
};

export default Navigation;
