import { makeStyles } from "@mui/styles";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { IconButton } from "@mui/material";
// import { Button } from "../../FormFields/Buttons";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    padding: 16,
  },
  title: {
    flex: 1,
    paddingLeft: 16,
    fontSize: "1.2rem",
  },
}));

type DrawerHeaderType = {
  title?: any;
  direction: "left" | "right";
  onClose: () => void;
  className?: string;
};
export const DrawerHeader = ({
  title,
  direction,
  onClose,
  className,
}: DrawerHeaderType) => {
  const classes = useStyles();
  return (
    <div className={`${classes.root} ${className}`}>
      {direction === "left" && (
        <>
          <div className={classes.title}>{title}</div>
          <IconButton onClick={onClose}>
            <ChevronLeftIcon />
          </IconButton>
        </>
      )}
      {direction === "right" && (
        <>
          <IconButton onClick={onClose}>
            <ChevronRightIcon />
          </IconButton>
          <div className={classes.title}>{title}</div>
        </>
      )}
    </div>
  );
};
