import axios from "axios";
import { ObjectType } from "../Types";

let cancelTokenSource: ObjectType = {};

export const getImageAsync = async (code: string) => {
  const { data } = await axios.get(`/image/${code}`);
  return data;
};

export const getImage = (codeBuffer: string, config: ObjectType) => {
  const key = codeBuffer; // "image";
  if (cancelTokenSource[key]) {
    cancelTokenSource[key].cancel("canceled request");
  }
  cancelTokenSource[key] = axios.CancelToken.source();
  const newConfig = {
    cancelToken: cancelTokenSource[key].token,
    ...config,
  };
  return axios.post(`/image`, { codeBuffer }, newConfig);
};

export const getImages = (codeBuffers: string[], config: ObjectType) => {
  const key = "images";
  if (cancelTokenSource[key]) {
    cancelTokenSource[key].cancel("canceled request");
  }
  cancelTokenSource[key] = axios.CancelToken.source();
  const newConfig = {
    cancelToken: cancelTokenSource[key].token,
    ...config,
  };
  return axios.post(`/images`, { codeBuffers }, newConfig);
};
