export enum DocumentType {
  EMPTY = "",
  ADMIN = "admin",
  ADMIN_LISTING = "admin_listing",
  ACCOUNT = "account",
  CART = "cart",
  CART_ITEM = "cart_item",
  CREATOR_DASHBOARD = "creator_dashboard",
  CUSTOMER = "customer",
  CUSTOMER_ORDERS = "customer_orders",
  STICKER_FILE = "sticker_file",
  DESIGNER = "designer",
  EASY_POST = "easy_post",
  EXIT_SURVEY = "exit_survey",
  FOLLOW = "follow",
  FRONT_PAGE = "front_page",
  IMAGE = "image",
  IMAGE_UPLOAD_URL = "image_upload_url",
  LISTING = "listing",
  MERCHANT = "merchant",
  OPEN_AI = "open_ai",
  ORDER = "order",
  ORDER_CONFIRMATION = "order_confirmation",
  ORDER_ITEM = "order_item",
  PRODUCT = "product",
  REFERRAL = "referral",
  SHOP = "shop",
  SHOP_ORDERS = "shop_orders",
  SHIPPING_LABEL = "shipping_label",
  STORE = "store",
  STORE_ORDERS = "store_orders",
  STRIPE = "stripe",
  USER = "user",
}

export enum ModuleLayout {
  WITH_HEADER = "WITH_HEADER",
  WITHOUT_HEADER = "WITHOUT_HEADER",
  PUBLIC_HEADER = "PUBLIC_HEADER",
  CREATOR_DASHBOARD = "CREATOR_DASHBOARD",
}

export enum AuthStatus {
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
}

export enum QueryType {
  LISTINGS = "listings",
}

export enum ListingStatus {
  PENDING_REVIEW = "pending_review",
  ACTIVE = "active",
  FEEDBACK = "feedback",
  INACTIVE = "inactive",
}
