import { get } from "lodash-es";

type options = {
  id: string;
  content: any;
  [textKey: string]: string;
};

type option = {
  key: string;
  text: string;
  value: string;
  content: any;
};
export const useArrayToOptions = (arr: options[] = [], textKey = "name") => {
  // const classes = useStyles();
  return arr.reduce((acc, item) => {
    let content;
    // if (item.content) {
    //   content = (
    //     <div className={classes.root}>
    //       <div>{get(item, textKey)}</div>
    //       <Icon name={item.content.icon} />
    //     </div>
    //   );
    // }
    acc.push({
      key: item.id,
      text: get(item, textKey),
      value: item.id?.toString(),
      content,
    });
    return acc;
  }, [] as option[]);
};
