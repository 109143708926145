import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@mui/styles";
import { createTheme, Theme } from "@mui/material/styles";
import { Provider as ReduxProvider } from "react-redux";
import { ToastProvider } from "react-toast-notifications";
import { LiveChatWidget } from "@livechat/widget-react";

// import { PersistGate } from "redux-persist/integration/react";
import { QueryClient, QueryClientProvider } from "react-query";
import LogRocket from "logrocket";
import ReactPixel from "react-facebook-pixel";

import { store } from "./store/store";
import { Router } from "./Router";
import { Axios } from "./axios/axios";
import ReactGA from "react-ga4";
import ScrollToTop from "./components/UI/ScrollToTop";
import CartId from "./components/UI/CartId";
import { useEffect } from "react";
import NewCustomerAd from "./components/UI/NewCustomerAd";
import AIImageAd from "./components/UI/AIImageAd";

if (process.env.REACT_APP_ENV === "production") {
  // LogRocket.init("snani5/sticker-delivery", { uploadTimeInterval: 2 });
  ReactGA.initialize("G-FJWS5CRSJD");
  ReactPixel.init("646336229830804", undefined, {
    autoConfig: true,
    debug: false,
  });
}

declare module "@mui/styles" {
  interface DefaultTheme extends Theme {}
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
    },
  },
});

const theme = createTheme({
  palette: {
    primary: {
      main: "#333",
      dark: "#5b4d34", //"#5e7744", //"#447744",
      light: "#eee",
    },
    secondary: {
      main: "#f6f1c7",
      dark: "#296699",
      light: "#333",
    },
    success: {
      main: "#b7e060",
      light: "#cde0a6",
      dark: "#73a82d",
    },
    warning: {
      main: "#E0D7A6",
      light: "#e0d6a6",
      dark: "#E0D7A6",
    },
    action: {
      active: "#4183c4",
      hover: "#ddd",
    },
  },
});

export const App = () => {
  console.log("app");
  ReactPixel.pageView();
  useEffect(() => {
    if (process.env.REACT_APP_ENV === "production") {
      setTimeout(() => {
        LogRocket.init("snani5/sticker-delivery", { uploadTimeInterval: 2 });
      }, 1000 * 2);
    }
  }, []);
  return (
    <ReduxProvider store={store}>
      {/* <NewCustomerAd /> */}

      {/* <LiveChatWidget license="14802258" visibility="minimized" /> */}
      <ThemeProvider theme={theme}>
        <ToastProvider autoDismiss placement="top-right">
          <QueryClientProvider client={queryClient}>
            <Axios>
              <BrowserRouter>
                {/* <CartId />
                <ScrollToTop /> */}
                <Router />
                {/* <AIImageAd /> */}
              </BrowserRouter>
            </Axios>
          </QueryClientProvider>
        </ToastProvider>
      </ThemeProvider>
    </ReduxProvider>
  );
};
