import { createSlice } from "@reduxjs/toolkit";
import { set, setWith, pick } from "lodash-es";
import { ObjectType } from "../Types";
import { RootState } from "./store";

type AuthSliceType = {
  items: ObjectType[];
};
export const authSlice = createSlice({
  name: "cart",
  initialState: {
    items: [],
  } as AuthSliceType,
  reducers: {
    addItem: (state, action) => {
      state.items.push(action.payload);
    },
    removeItem: (state) => {
      state.items.pop();
    },
  },
});

export const { addItem, removeItem } = authSlice.actions;
export const getItems = (state: RootState) => state.cart.items;

export default authSlice.reducer;
