import { makeStyles } from "tss-react/mui";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import { useResponsive } from "../../hooks/useResponsive";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles<{ isDesktop: boolean }>()(
  (theme, { isDesktop }) => ({
    root: {
      display: "flex",
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingLeft: isDesktop ? theme.spacing(2) : theme.spacing(1),
      paddingRight: isDesktop ? theme.spacing(2) : theme.spacing(1),
      backgroundColor: "#eee",
      color: "#000",
      fontSize: "0.9rem",
      borderBottomLeftRadius: 4,
      borderBottomRightRadius: 4,
      div: {
        flex: 1,
      },
    },
  })
);

type PageProps = {};
export const HeaderBar = ({}: PageProps) => {
  const { isDesktop } = useResponsive();
  const navigate = useNavigate();
  const { classes } = useStyles({ isDesktop });
  return (
    <>
      <div className={classes.root}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flex: !isDesktop ? 1.5 : 1,
          }}
        >
          {isDesktop && (
            <QueryBuilderIcon fontSize="small" style={{ marginRight: 8 }} />
          )}{" "}
          8am - 5pm, Monday - Friday PST
        </div>
        {isDesktop && (
          <>
            <div style={{ textAlign: "center" }}>
              FREE SHIPPING on <b>ALL ORDERS!</b>
            </div>
            <div
              style={{ textAlign: "center", cursor: "pointer" }}
              onClick={() => navigate("/cashback")}
            >
              <b>$</b> Earn Cashback Rewards!
            </div>
          </>
        )}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          {isDesktop && (
            <LocalPhoneIcon fontSize="small" style={{ marginRight: 8 }} />
          )}{" "}
          (949) 220-2953
        </div>
      </div>
      {!isDesktop && (
        <div
          style={{
            display: "flex",
            paddingLeft: 8,
            paddingRight: 8,
            marginTop: 2,
          }}
        >
          <div style={{ textAlign: "left", fontSize: "0.9rem", flex: 1.5 }}>
            FREE SHIPPING <b>ALL ORDERS!</b>
          </div>
          <div
            style={{
              textAlign: "right",
              fontSize: "0.9rem",
              flex: 1,
            }}
            onClick={() => navigate("/cashback")}
          >
            <b>$</b> Cashback Rewards!
          </div>
        </div>
      )}
    </>
  );
};

export default HeaderBar;
