import React, { ReactNode, SyntheticEvent, useState } from "react";
import { makeStyles } from "@mui/styles";
// import {
//   Dropdown as SemanticDropdown,
//   DropdownProps,
//   DropdownItemProps,
// } from "semantic-ui-react";
import classnames from "classnames";

import { useArrayToOptions } from "../../hooks/useArrayToOptions";
import { ObjectType } from "../../Types";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    color: `${theme.palette?.grey[800]} !important`,
    backgroundColor: `${theme.palette?.grey[300]} !important`,
    "&.ui.dropdown:not(.button)>.default.text": {
      color: `${theme.palette?.grey[500]} !important`,
    },
    "& .menu > .item": {
      border: "0px !important",
      backgroundColor: `${theme.palette?.grey[400]} !important`,
    },
    "& .menu > .item:hover": {
      backgroundColor: `#a0a0a0 !important`,
    },
    borderColor: "transparent !important",
  },
  group: {
    backgroundColor: theme.palette?.grey[900],
    padding: "16px 0 6px 16px !important",
    margin: "0 !important",
  },
  error: {
    borderColor: "#ac2525 !important",
  },
}));

type DropdownType = {
  name: string;
  options: ObjectType[];
  onUpdate: (
    name: string,
    value: string | undefined | ObjectType,
    option?: any
  ) => void;
  onClick?: (e: any) => void;
  value?: string;
  label?: string;
  style?: object;
  upward?: boolean;
  hasError?: boolean;
  loading?: boolean;
  clearable?: boolean;
  disabled?: boolean;
  search?: boolean;
  textKey?: string;
  treeview?: boolean;
  size?: "small" | "medium" | undefined;
};
export const Dropdown = ({
  name,
  options = [],
  label = "Choose",
  onUpdate,
  onClick,
  style,
  upward = false,
  hasError,
  value,
  textKey = "name",
  loading,
  clearable = true,
  disabled,
  search = true,
  treeview,
  size,
}: DropdownType) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const handleOptionChanged = (
    event: SelectChangeEvent<string>,
    child: ReactNode
  ) => {
    // console.log("name: ", name, value);
    onUpdate(
      name,
      event.target.value,
      options.find((o) => o.id === value)
    );
  };
  // const handleOptionClicked = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, { value }: DropdownItemProps) => {
  //   console.log("closing");
  //   // setOpen(false);
  //   onUpdate(name, value?.toString());
  //   event.stopPropagation();
  // };

  const newOptions: any[] = [];
  if (treeview) {
    const buildChild = ({
      parentId,
      level,
    }: {
      parentId: string;
      level: number;
    }) => {
      const nodes = options.filter((o) => o.parentId === parentId);
      nodes.forEach((node) => {
        const newNode = { ...node };
        let spaces = "";
        for (let i = 0; i < level; i++) {
          spaces = ` - ${spaces}`;
        }
        newNode.name = `${spaces} ${newNode.name}`;
        newNode.level = level;
        newOptions.push(newNode);
        buildChild({ parentId: node.id, level: level + 1 });
      });
    };

    const level = 0;
    const rootNodes = options.filter((o) => !o.parentId);
    rootNodes.forEach((rootNode) => {
      const parentId = rootNode.id;
      newOptions.push({ ...rootNode, level });
      buildChild({ parentId, level: level + 1 });
    });
  }

  const dropdownOptions = newOptions.length ? newOptions : options;
  const optionsFormatted = useArrayToOptions(dropdownOptions, textKey);
  // console.log("optionsFormatted: ", optionsFormatted);

  return (
    <>
      <FormControl fullWidth style={style}>
        {label && (
          <InputLabel id="select-label" error={hasError}>
            {label}
          </InputLabel>
        )}
        <Select
          labelId="select-label"
          value={value}
          label={label}
          onChange={handleOptionChanged}
          // style={style}
          error={hasError}
          size={size}
        >
          {optionsFormatted.map((option) => (
            <MenuItem value={option.value}>{option.text}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};
