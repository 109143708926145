import axios from "axios";
import { DocumentType } from "../Enums";
import { ObjectType } from "../Types";

const publicDocumentTypes = [
  DocumentType.ACCOUNT,
  DocumentType.DESIGNER,
  DocumentType.FRONT_PAGE,
  DocumentType.CART,
  DocumentType.CART_ITEM,
  DocumentType.EXIT_SURVEY,
  DocumentType.OPEN_AI,
  DocumentType.ORDER_CONFIRMATION,
  DocumentType.PRODUCT,
  DocumentType.REFERRAL,
  DocumentType.STRIPE,
];

export const post = async (params: ObjectType, config?: ObjectType) => {
  const isPublic = publicDocumentTypes.includes(params.documentType);
  const path = isPublic ? "/api" : "/api-p";
  const { data } = await axios.post(path, params, config);
  return data;
};

export const get = async (params: ObjectType, config?: ObjectType) => {
  return post({ method: "get", ...params }, config);
};

export const getById = async (params: ObjectType, config?: ObjectType) => {
  return post({ method: "getById", ...params }, config);
};

export const getFiltered = async (params: ObjectType, config?: ObjectType) => {
  // console.log("getfiltered: ", params);
  return post({ method: "getFiltered", ...params }, config);
};

export const insert = async (params: ObjectType, config?: ObjectType) => {
  return post({ method: "insert", ...params }, config);
};

export const update = async (params: ObjectType, config?: ObjectType) => {
  return post({ method: "update", ...params }, config);
};

export const remove = async (params: ObjectType, config?: ObjectType) => {
  return post({ method: "remove", ...params }, config);
};
