import { createSlice } from "@reduxjs/toolkit";
import { set, setWith, pick } from "lodash-es";
import { ObjectType } from "../Types";
import { RootState } from "./store";

type AuthSliceType = {
  sharedTags: string[];
  words: string[];
};
export const authSlice = createSlice({
  name: "general",
  initialState: {
    sharedTags: [],
    words: [],
  } as AuthSliceType,
  reducers: {
    setSharedTags: (state, action) => {
      state.sharedTags = action.payload;
    },
    addWords: (state, action) => {
      // state.words = [];
      const words = action.payload.split(" ");
      if (!state.words) {
        state.words = [];
      }
      words.forEach((word: string) => {
        if (!state.words.includes(word.toLowerCase())) {
          const newWords = [...state.words];
          newWords.push(word.toLowerCase());
          state.words = newWords;
        }
      });
    },
  },
});

export const { setSharedTags, addWords } = authSlice.actions;
export const getSharedTags = (state: RootState) => state.general.sharedTags;
export const getWords = (state: RootState) => state.general?.words;

export default authSlice.reducer;
