import { useLocation, useParams } from "react-router-dom";
import { makeStyles } from "tss-react/mui";
import { useResponsive } from "../../hooks/useResponsive";
import { ObjectType } from "../../Types";
import PublicHeader from "../Header/PublicHeader";
import Navigation from "../Navigation/Navigation";

const useStyles = makeStyles<{ isDesktop: boolean }>()(
  (theme, { isDesktop }) => ({
    root: {
      height: "100vh",
      display: "flex",
      flexDirection: "column",
      maxWidth: 1920,
      marginLeft: "auto",
      marginRight: "auto",
    },
    body: {
      flex: 1,
      display: "flex",
      flexDirection: isDesktop ? "row" : "column",
    },
  })
);

const publicMenu: ObjectType[] = [
  {
    name: "Account",
  },
];

const navigation: ObjectType = {
  categories: [
    {
      id: "profile",
      name: "Profile",
      path: "/creator-dashboard/profile",
    },
    {
      id: "customer-orders",
      name: "Purchase History",
      path: "/creator-dashboard/customer-orders",
    },
    {
      id: "referral-rewards",
      name: "Referral Rewards",
      path: "/creator-dashboard/referral-rewards",
    },
    // {
    //   id: "messages",
    //   name: "Messages",
    //   badge: "3",
    //   path: "/creator-dashboard/messages",
    // },
    {
      id: "dashboard",
      name: "Creator Dashboard",
      path: "/creator-dashboard/dashboard",
    },
    {
      name: "Store",
      // private: true,
      stripe: true,
      children: [
        {
          id: "listings",
          name: "Listings",
          path: "/creator-dashboard/listings",
        },
        {
          id: "store-orders",
          name: "Orders",
          path: "/creator-dashboard/store-orders",
        },
        {
          id: "store-settings",
          name: "Settings",
          path: "/creator-dashboard/store-settings",
        },
      ],
    },
    {
      name: "Shop",
      private: true,
      children: [
        {
          id: "shop-orders",
          name: "Orders",
          path: "/creator-dashboard/shop-orders",
        },
        {
          id: "shop-settings",
          name: "Settings",
          path: "/creator-dashboard/shop-settings",
        },
      ],
    },
    {
      name: "Admin",
      private: true,
      children: [
        {
          id: "admin-listings",
          name: "Listings",
          path: "/creator-dashboard/admin-listings",
        },
      ],
    },
  ],
};

type PageProps = {
  children: any;
};
export const CreatorDashboardTemplate = ({ children }: PageProps) => {
  const { isDesktop } = useResponsive();
  const { classes } = useStyles({ isDesktop });
  const { pathname } = useLocation();

  return (
    <div className={classes.root}>
      <PublicHeader publicMenu={publicMenu} />
      <div className={classes.body}>
        <Navigation navigation={navigation} path={pathname} />
        {children}
      </div>
    </div>
  );
};

export default CreatorDashboardTemplate;
