import { Link } from "react-router-dom";
import { makeStyles } from "tss-react/mui";
import { useContactUs } from "../../hooks/useContactUs";
import { useResponsive } from "../../hooks/useResponsive";

const useStyles = makeStyles<{ isDesktop: boolean }>()(
  (theme, { isDesktop }) => ({
    root: {
      // position: "sticky",
      // bottom: 0,
      backgroundColor: "#eee",
      borderTop: "1px solid #ddd",
      color: "#333",
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      marginTop: theme.spacing(10),
    },
    container: {
      maxWidth: 1280,
      margin: "auto",
      display: "flex",
      alignItems: "flex-start",
      flexDirection: isDesktop ? "row" : "column",
    },
    logoContainer: {
      // width: 300,
      flex: 1,
    },
    logo: {
      // width: 200,
      fontFamily: "Pacifico",
      fontWeight: "bold",
      color: "#333344",
      fontSize: 48,
    },
    copyright: {
      fontSize: "0.9rem",
      marginTop: theme.spacing(1),
    },
    links: {
      flex: 1,
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      flexDirection: isDesktop ? "row" : "column",
      a: {
        marginBottom: theme.spacing(1),
      },
    },
    divider: {
      borderLeft: "1px solid #ccc",
      width: 1,
      height: 20,
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    customerService: {
      // flex: 1,
      display: "flex",
      justifyContent: "flex-end",
      // alignItems: "flex-start",
      flexDirection: "column",
      // marginLeft: theme.spacing(12),
      a: {
        marginBottom: theme.spacing(1),
      },
    },
    contact: {
      flex: 1,
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "flex-start",
      flexDirection: "column",
      // marginLeft: theme.spacing(24),
    },
  })
);

type PageProps = {};
export const Footer = ({}: PageProps) => {
  const { isDesktop } = useResponsive();
  const { classes } = useStyles({ isDesktop });
  const contactUs = useContactUs();
  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div
          className={classes.logoContainer}
          style={{ width: isDesktop ? 300 : "50%" }}
        >
          <div className={classes.logo}>Sticker</div>

          {/* <img src="app-logo-1.png" alt="store logo" className={classes.logo} /> */}
          <div className={classes.copyright}>
            Copyright © 2022 All rights reserved.
          </div>
        </div>
        <div style={{ flex: 1 }}>
          <div className={classes.customerService}>
            <h3>Customer Service</h3>
            <Link to="/privacy-policy">Privacy Policy</Link>
            <Link to="/shipping-policy">Shipping & Delivery</Link>
            <Link to="/return-policy">Return & Refund Policy</Link>
            <Link to="/terms-of-service">Terms Of Service</Link>
            <div>
              <img
                src="/cc-images/multi.png"
                alt="discover"
                style={{ marginTop: 8 }}
              />
            </div>
          </div>
        </div>

        <div className={classes.contact}>
          <div>
            <h3>Contact</h3>
            <p>
              23986 Aliso Creek Rd, Pmb 307
              <br />
              Laguna Niguel, CA 92677
            </p>
            <p>(949) 220-2953</p>
            <p>contact@sticker.delivery</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
