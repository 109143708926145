import { Suspense, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { v4 } from "uuid";

import { ModuleLayout } from "./Enums";
import { Pages } from "./pages";
import { Loading } from "./components/UI/Loading";
import PublicHeader from "./components/Header/PublicHeader";
import CreatorDashboardTemplate from "./components/Templates/CreatorDashboardTemplate";
import { useSelector } from "react-redux";
import { getCartId, setCartId } from "./store/authSlice";
import { useDispatch } from "react-redux";
import Logo from "./components/UI/Logo";
import HeaderBar from "./components/UI/HeaderBar";
import Footer from "./components/UI/Footer";
import { CircularProgress, LinearProgress } from "@mui/material";
import { useAuth } from "./hooks/useAuth";
import { useCartId } from "./hooks/useCartId";

const useStyles = makeStyles((theme) => ({
  root: {
    // color: theme.palette.primary.main,
    // display: "flex",
    // flexDirection: "column",
    // width: "100vw",
    // height: "100vh",
    // maxWidth: 1920,
    // marginLeft: "auto",
    // marginRight: "auto",
    "& ::-webkit-scrollbar": {
      width: 4,
      height: 0,
      backgroundColor: "transparent",
    },
  },
  container: {
    overflowX: "hidden",
    overflowY: "hidden",
    height: "100%",
    display: "flex",
    flex: 1,
  },
  body: {
    color: theme.palette.primary.main,
    display: "flex",
    flexDirection: "column",
    width: "100vw",
    // height: "100vh",
    maxWidth: 1920,
    marginLeft: "auto",
    marginRight: "auto",
  },
}));

type RenderType = {
  page: any;
};
const Render = (props: RenderType) => {
  const { page } = props;
  const classes = useStyles();
  const { isAuthenticated, isSuperAdmin } = useAuth();
  const Component = page.component;

  if (!isAuthenticated && page.private) {
    return <Navigate to="/login" />;
  }

  if (page.layout === ModuleLayout.CREATOR_DASHBOARD) {
    return (
      <CreatorDashboardTemplate>
        <Component />
      </CreatorDashboardTemplate>
    );
  }

  if (page.layout === ModuleLayout.WITHOUT_HEADER) {
    return (
      <div
        id="mainContainer"
        className={classes.container}
        style={{ overflowY: page.scroll ? "auto" : "hidden" }}
      >
        <Component />
      </div>
    );
  }
  if (page.layout === ModuleLayout.PUBLIC_HEADER) {
    const publicMenu = [
      {
        name: "Shop",
        path: "/",
      },
      {
        name: "Contact",
        path: "/contact",
      },
      {
        name: "AI Generated",
        path: "/custom-image",
      },
      // {
      //   name: "Sell Designs",
      //   path: "/sell-designs",
      // },
      {
        name: "Cashback",
        path: "/cashback",
      },
    ];
    return (
      <>
        <div
          className={classes.body}
          style={{
            height: page.footer ? undefined : "100vh",
            minHeight: "80vh",
          }}
        >
          <HeaderBar />
          <PublicHeader
            publicMenu={publicMenu}
            headerShadow={page.headerShadow}
          />
          <Component />
        </div>
        {page.footer && <Footer />}
      </>
    );
  }
  return <Component />;
};

export const Router = () => {
  const classes = useStyles();

  // const dispatch = useDispatch();
  // const cartId = useSelector(getCartId);
  // useEffect(() => {
  //   if (!cartId) {
  //     dispatch(setCartId(v4()));
  //   }
  // }, [cartId, dispatch]);
  return (
    <div className={classes.root}>
      <Suspense
        fallback={
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
              flexDirection: "column",
            }}
          >
            <Logo />
            <div style={{ width: 68 }}>
              <LinearProgress />
            </div>
          </div>
        }
      >
        <Routes>
          {Pages.map((page) => {
            return (
              <Route
                path={page.path}
                element={<Render page={page} />}
                key={page.path}
              />
            );
          })}
        </Routes>
      </Suspense>
    </div>
  );
};
