import { useNavigate } from "react-router-dom";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  root: {
    fontSize: 32,
    fontFamily: "Pacifico",
    fontWeight: "bold",
    color: "#334",
    cursor: "pointer",
  },
}));

type PageProps = {};
export const Logo = ({}: PageProps) => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  return (
    <div className={classes.root} onClick={() => navigate("/")}>
      Sticker
    </div>
  );
};

export default Logo;
